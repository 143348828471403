<template>
  <div id="confirm-an-rder">
    <el-steps :active="1" finish-status="success" :align-center="true">
      <el-step title="我的购物车"></el-step>
      <el-step title="确认订单信息"></el-step>
      <el-step title="成功提交订单"></el-step>
    </el-steps>
    <div class="order-address">
      <h2>确认订单信息</h2>
      <div class="consignee-information">
        <div class="title">
          <p>收货人信息</p>
          <span @click="add">新增收货地址</span>
        </div>
        <ul>
          <li
            class="onUser"
            :style="Default.length <= 1 ? 'margin: 0' : ''"
            v-show="Default.length != 0"
          >
            <div class="user">
              <p>
                {{ Default.nameAddress }}

                <img src="@/assets/images/209.png" />
              </p>
              <span>{{ Default.phoneAddress }} </span>
              <span>{{ Default.consignee }} </span>
              <span> {{ Default.nameAddress }}</span>
              <span
                >{{ Default.province }}{{ Default.city }}{{ Default.county
                }}{{ Default.detailAddress }}</span
              >
              <i>默认地址</i>
            </div>
            <div class="operation">
              <span @click="Edit(Default)">编辑</span>
              <!-- <el-popconfirm
                confirm-button-text="好的"
                cancel-button-text="不用了"
                icon="el-icon-info"
                icon-color="red"
                title="这是一段内容确定删除吗？"
              > -->
              <span @click="deleteAddress(Default.id)" slot="reference"
                >删除</span
              >
              <!-- </el-popconfirm> -->
            </div>
          </li>
          <p
            style="font-size: 14px; color: #999999"
            v-show="Default.length === 0 && address.length === 0"
          >
            暂时没有任何地址信息，请先新增收货地址！
          </p>
        </ul>
        <el-collapse-transition>
          <ul ref="UL" v-show="More">
            <li v-for="(list, index) in address" :key="index">
              <div class="user">
                <p class="name">
                  {{ list.nameAddress }}
                </p>
                <span>{{ list.phoneAddress }}</span>
                <span>{{ list.consignee }}</span>
                <span>{{ list.nameAddress }}</span>
                <span
                  >{{ list.province }}{{ list.city }}{{ list.county
                  }}{{ list.detailAddress }}</span
                >
              </div>
              <div class="operation" style="display: none">
                <span @click="SetTheDefault(list.id)">设为默认地址</span>
                <span @click="Edit(address[index])">编辑</span>
                <!-- <el-popconfirm title="这是一段内容确定删除吗？"> -->
                <span @click="deleteAddress(list.id)" slot="reference"
                  >删除</span
                >
                <!-- </el-popconfirm> -->
              </div>
            </li>
          </ul>
        </el-collapse-transition>
        <p
          class="more"
          @click="more"
          v-show="address.length > 0 && Default.length != 0"
        >
          {{ More ? "收起地址" : "更多地址"
          }}<svg-icon
            icon-class="arrow-bottom"
            :style="{ transform: More ? 'rotate(-180deg)' : 'rotate(0)' }"
          />
        </p>
      </div>
      <div class="pay">
        <h3>支付方式：</h3>
        <p>在线支付<img src="@/assets/images/209.png" /></p>
      </div>
    </div>
    <div class="order-content" v-for="(order, i) in store" :key="i">
      <div class="title">
        <svg-icon icon-class="ic-shop" />
        店铺：<span>{{ order.storeName }}</span>
      </div>
      <div class="content">
        <h3>货品明细：</h3>
        <div class="goods-box">
          <div class="goods-title">
            <p>货品</p>
            <p>数量</p>
            <p>单价（元）</p>
            <p>金额（元）</p>
            <p>运费（元）</p>
          </div>
          <div
            class="goods"
            v-for="(list, index) in order.orderCardListItems"
            :key="index"
          >
            <img v-lazy="list.filePath" style="width: 84px; height: 84px" />
            <div class="details">
              <h3 :title="list.commodityName">
                <span>品名：</span>{{ list.commodityName }}
              </h3>
              <p>
                品牌：<span>{{ list.brandName }}</span>
              </p>
              <p>
                规格：<span>{{ list.specsParameter }}</span>
              </p>
            </div>
            <p class="num">{{ list.quantity }}</p>
            <p class="unit-price">{{ list.price | price }}</p>
            <p class="money">
              {{ (list.quantity * list.price) | price }}
            </p>
            <p class="freight">另外计算</p>
          </div>
          <div class="message"></div>
        </div>
        <div class="leave-a-message">
          <div class="left">
            <span>买家留言：</span>
            <div class="cont" v-show="!order.messageSelected">
              <input
                type="text"
                placeholder="若有特殊要求，需先和商家协商一致，否则填写内容将视为无效。"
                maxlength="500"
                v-model="order.message"
                @focus="getFocus(i)"
              />
              <p>
                <span>{{ order.message.length }}</span
                ><i>／</i>500
              </p>
            </div>
            <div class="text" v-show="order.messageSelected">
              <textarea
                v-model="order.message"
                @blur="order.messageSelected = false"
                maxlength="500"
                :ref="`content${i}`"
              />
              <div>
                <p>
                  若有特殊要求，需先和商家协商一致，否则填写内容将视为无效。
                </p>
                <p>
                  <span>{{ order.message.length }}</span
                  ><i>／</i>500
                </p>
              </div>
            </div>
          </div>
          <div class="right">
            <p>
              货品总金额：<span>{{ order.allNum | price }}</span
              >&nbsp;元
            </p>
            <p>运费：另外计算</p>
          </div>
        </div>
      </div>

      <div class="waves"></div>
    </div>
    <div class="settlement">
      <p @click="goBack">返回购物车</p>
      <div class="right">
        <span class="rental"
          >应付总额（不含运费）：<span>{{ allPrice | price }}</span
          >&nbsp;元</span
        >
        <button @click="submit">提交订单</button>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div class="form" v-if="newAddress">
        <div class="box">
          <div class="title">
            <span>{{ FormState === 0 ? "新增" : "编辑" }}收货地址</span>
            <svg-icon icon-class="ic-close" @click="Tip" />
          </div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="122px"
            class="demo-ruleForm"
            label-position="left"
            size="small"
            status-icon
          >
            <el-form-item label="收货单位">
              <el-input v-model="ruleForm.ReceivingUnit"></el-input>
            </el-form-item>
            <el-form-item label="收货地址" prop="ShippingAddress">
              <el-cascader
                size="large"
                :options="options"
                v-model="ruleForm.ShippingAddress"
                @change="handleChange"
              >
              </el-cascader>
              <!-- <el-input v-model="ruleForm.ShippingAddress"></el-input> -->
            </el-form-item>
            <el-form-item label="详细地址" prop="DetailedAddress">
              <el-input v-model="ruleForm.DetailedAddress"></el-input>
            </el-form-item>
            <el-form-item label="收货联系人" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="联系人手机号码" prop="num">
              <el-input v-model.number="ruleForm.num"></el-input>
            </el-form-item>
            <el-form-item label="是否默认地址" prop="default" status-icon>
              <el-radio-group v-model="ruleForm.default">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false" :disabled="!Default ? true : false"
                  >否</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-form>
          <button @click="submitForm('ruleForm')">保存收货地址</button>
        </div>
      </div>
    </transition>
    <!-- <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" center>
      <span>所填的信息将不被保存，确定关闭窗口？</span>
      <span slot="footer" class="dialog-footer">
        <button @click="closeForm">确定关闭</button>
        <button @click="dialogVisible = false">取消关闭</button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  name: "confirm-an-rder",
  data() {
    return {
      orderNum: [],
      FormState: 0,
      options: regionData, //城市信息
      selectedOptions: "", //选中的城市信息
      store: "", //商品信息
      address: [], //用户地址
      goods: "",
      Default: "", //默认第一条信息
      message: "", // 留言框信息
      // dialogVisible: false, //新增地址关闭弹窗
      OnInput: false, //留言框是否展开
      More: false, //更多地址
      newAddress: false, //新增地址显示隐藏
      ruleForm: {
        ReceivingUnit: "", //收货单位
        ShippingAddress: "", //所在地区
        DetailedAddress: "", //详情地址
        name: "", //名字
        num: "", //手机号
        default: "", //是否默认地址
        addressCode: "", //地区号
      },
      rules: {
        ShippingAddress: [
          { required: true, message: "请选择所在地区", trigger: "blur" },
        ],
        DetailedAddress: [
          { required: true, message: "请填写详细地址", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请填写收货联系人", trigger: "blur" },
          { max: 6, message: "最多为六个字符", trigger: "blur" },
        ],
        num: [
          { required: true, message: "请填写联系人手机号码", trigger: "blur" },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (/^(?:(?:\+|00)86)?1\d{10}$/.test(value) == false) {
                callback(new Error("手机号格式不正确"));
              } else {
                callback();
              }
            },
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        default: [
          { required: true, message: "请选择是否默认地址", trigger: "blur" },
        ],
      },
    };
  },
  watch: {
    // FormState: {
    //   handler: function (val, oldVal) {
    //     console.log(val);
    //   },
    // },
  },
  computed: {
    allPrice() {
      let allPrice = 0;
      for (const store of this.store) {
        allPrice += store.allNum;
      }
      return allPrice;
    },
  },
  filters: {
    //处理函数
    price(num) {
      // console.log(Number(num).toFixed(2));
      // return num
      if (num === null) {
        return "-";
      } else if (num == 0) {
        return num;
      } else if (String(Number(num)).indexOf(".")) {
        // return num;
        return Number(num).toFixed(2);
      } else {
        return num;
      }
      // console.log(value);
      // return value + ".00";
      // console.log(value.toString().split(".")[1].length);
    },
  },
  created() {
    this.getAddress();
    this.store = JSON.parse(sessionStorage.getItem("goods"));
    if (!this.store) {
      this.$router.push({
        path: `ShoppingCart`,
      });
    }
  },

  methods: {
    //新增收货地址按钮
    add() {
      this.FormState = 0;
      for (let k in this.ruleForm) {
        this.ruleForm[k] = "";
      }
      this.newAddress = true;
    },
    //新增收货地址接口
    async insertAddress() {
      const data = await this.$API.personalUser.insertAddress({
        object: {
          addressCode: this.ruleForm.addressCode,
          province: this.selectedOptions[0],
          city: this.selectedOptions[1],
          county: this.selectedOptions[2] ? this.selectedOptions[2] : "",
          consignee: this.ruleForm.ReceivingUnit,
          country: "",
          defaultAddress: this.ruleForm.default,
          detailAddress: this.ruleForm.DetailedAddress,
          id: "",
          nameAddress: this.ruleForm.name,
          phoneAddress: this.ruleForm.num,
        },
      });
      this.getAddress();
    },
    //查询收货地址
    async getAddress() {
      this.address = [];
      const data = await this.$API.personalUser.queryAddress({
        object: {
          pageNum: 1,
          pageSize: 1,
        },
      });
      let t = data.data.data;

      let address = []; //不是默认地址
      let Address = ""; //默认地址
      for (let i = 0; i < t.length; i++) {
        if (!t[i].defaultAddress) {
          address.push(t[i]);
        } else {
          Address = t[i];
        }
      }

      this.address = address; //不默认地址列表
      this.Default = Address; //默认地址列表
      if (this.Default === "") {
        this.More = true;
      }
    },
    //修改地址
    async updateAddress(id) {
      if (this.FormState === 1) {
        console.log(this.ruleForm);
        const data = await this.$API.personalUser
          .updateAddress({
            object: {
              addressCode: this.ruleForm.addressCode,
              province: this.selectedOptions[0],
              city: this.selectedOptions[1],
              county: this.selectedOptions[2] ? this.selectedOptions[2] : "",
              consignee: this.ruleForm.ReceivingUnit,
              country: "",
              defaultAddress: this.ruleForm.default,
              detailAddress: this.ruleForm.DetailedAddress,
              id: this.ruleForm.id,
              nameAddress: this.ruleForm.name,
              phoneAddress: this.ruleForm.num,
            },
          })
          .then((data) => {
            this.$message({
              message: "更改成功",
              type: "success",
              offset: "450",
              duration: 1000,
            });
          })
          .catch((err) => {
            console.log(err);
            this.$message.error("更改失败");
          });

        this.getAddress();
      } else {
        this.$confirm("您确定要设置当前地址为默认地址吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const data = await this.$API.personalUser
              .updateAddress({
                object: {
                  defaultAddress: true,
                  id: id,
                },
              })
              .then((data) => {
                this.$message({
                  message: "设置成功",
                  type: "success",
                  offset: "450",
                  duration: 1000,
                });
              })
              .catch((err) => {
                console.log(err);
                this.$message.error("设置失败");
              });

            this.getAddress();
          })
          .catch(() => {
            this.$message({
              message: "取消成功",
              type: "success",
              offset: "450",
              duration: 1000,
            });
          });
      }
    },
    //编辑地址
    Edit(Default) {
      this.FormState = 1;
      // console.log(Default.province, Default.city, Default.county);
      let code = TextToCode[Default.province][Default.city][ Default.county].code;
      // let code = TextToCode["江苏省"]["徐州市"]["云龙区"].code;
      this.ruleForm = {
        addressCode: Default.addressCode,
        ReceivingUnit: Default.consignee, //收货单位
        ShippingAddress: code, //所在地区
        DetailedAddress: Default.detailAddress, //详情地址
        name: Default.nameAddress, //名字
        num: Default.phoneAddress, //手机号
        default: Default.defaultAddress, //是否默认地址
        id: Default.id,
        province: Default.province,
        city: Default.city,
        county: Default.county,
      };
      this.newAddress = true;
    },
    //删除收货地址
    async deleteAddress(id) {
      this.$confirm("您确定要删除选中地址吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const data = await this.$API.personalUser
            .deleteAddress({
              object: {
                id: id,
              },
            })
            .then((data) => {
              this.$message({
                message: "删除成功",
                type: "success",
                offset: "450",
                duration: 1000,
              });
            })
            .catch((err) => {
              console.log(err);
              this.$message.error("删除失败");
            });
          this.getAddress();
        })
        .catch(() => {
          this.$message({
            message: "取消成功",
            type: "success",
            offset: "450",
            duration: 1000,
          });
        });
    },

    goBack() {
      this.$router.push({
        path: `ShoppingCart`,
      });
    },
    //提交订单
    async submit() {
      if (this.Default === "" && this.address.length === 0) {
        this.$message.error("请添加收货地址");
      } else if (this.Default === "") {
        this.$message.error("请设置默认地址");
      } else {
        let order = [];
        for (let i = 0; i < this.store.length; i++) {
          this.store[i].totalQuantity = 0;
          let allGoods = {
            barcode: [], //条形码
            commId: [], //商品ID
            enterpriseId: [], //企业ID
            quantity: [], //销售数量
            sellingPrice: [], //销售金额
          };
          for (const list of this.store[i].orderCardListItems) {
            allGoods.barcode.push(list.barcode);
            allGoods.commId.push(list.commId);
            allGoods.enterpriseId.push(list.enterpriseId);
            allGoods.quantity.push(list.quantity);
            // allGoods.sellingPrice.push(list.price);
            allGoods.sellingPrice.push(0.01);
            this.store[i].totalQuantity += list.quantity;
          }
          order.push(allGoods);
        }
        let totalAmount = 0;
        let orderMessage = [];

        for (let i = 0; i < order.length; i++) {
          let message = {
            address: this.Default.detailAddress, //详细地址
            addressId: this.Default.id, //地址ID
            city: this.Default.city, //城市
            consignee: this.Default.nameAddress, //收货联系人
            country: "中国", //国家
            county: this.Default.county, //区县
            orderCommodityItems: order[i],
            penterpriseId: "", //采购方id
            province: this.Default.province, //省份
            receivingUnit: this.Default.consignee, //收货人单位
            senterpriseId: this.store[i].orderCardListItems[0].enterpriseId, //销售方id
            storeId: this.store[i].orderCardListItems[0].storeId, //店铺ID
            telephone: this.Default.phoneAddress, //收货人联系电话
            // totalAmount: this.allPrice, //订单总金额
            totalAmount: 0.01, //订单总金额
            totalQuantity: this.store[i].totalQuantity, //订单总数
            userUuid: sessionStorage.getItem("userId"),
            remarks: this.store[i].message,
          };
          // totalAmount += this.store[i].orderCardListItems.totalAmount;
          totalAmount += 0.01;
          orderMessage.push(message);
        }

        sessionStorage.setItem("totalAmount", totalAmount);

        for (let i = 0; i < orderMessage.length; i++) {
          const data = await this.$API.personalUser
            .TransactionOrder({
              object: orderMessage[i],
            })
            .then((res) => {
              this.orderNum.push(res.data.data.dealNumber);
            });
        }

        this.deleteGoods();
        sessionStorage.setItem("Time", new Date().getTime());
        sessionStorage.setItem("orderNum", this.orderNum);
        this.$router.push({
          path: `Pay/ModeOfPayment?orderNum=${this.orderNum}`,
        });
      }
    },

    //删除商品
    async deleteGoods() {
      let id = [];
      let goods = JSON.parse(sessionStorage.getItem("goods"));
      for (let i = 0; i < goods.length; i++) {
        for (const list of goods[i].orderCardListItems) {
          id.push(list.id);
        }
      }
      const data = await this.$API.personalUser
        .DeleteOrderCart({
          object: {
            id: id,
          },
        })
        .then((data) => {
          sessionStorage.removeItem("goods");
        });
    },
    //弹出提示框
    Tip() {
      // console.log(this.ruleForm);
      // for (let k in this.ruleForm) {
      //   if (this.ruleForm[k] != "") {
      //     this.t = true;
      //   }
      // }
      // if (this.t) {
      //   this.dialogVisible = true;
      // } else {
      //   this.$refs.ruleForm.clearValidate();
      //   this.newAddress = false;
      //   this.t = false;
      // }
      this.newAddress = false;
    },
    //提示框确定
    closeForm() {
      this.newAddress = false;
      this.dialogVisible = false;
      this.t = false;
      this.$refs["ruleForm"].resetFields();
      for (let k in this.ruleForm) {
        this.ruleForm[k] = "";
      }
    },
    //设置默认地址
    SetTheDefault(id) {
      this.FormState = 0;
      this.updateAddress(id);
    },
    // 点击input文本框获取焦点
    getFocus(i) {
      this.store[i].messageSelected = true;
      this.$nextTick(() => {
        this.$refs[`content${i}`][0].focus();
      });
    },
    //更多地址
    more() {
      this.More = !this.More;
    },
    //保存地址
    submitForm(formName) {
      // console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.FormState === 0
            ? this.insertAddress()
            : this.updateAddress(this.ruleForm.id);
          this.closeForm();
          // this.newAddress = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //选择城市
    handleChange() {
      let loc = [];
      let addressCode = "";
      for (let i = 0; i < this.ruleForm.ShippingAddress.length; i++) {
        loc.push(CodeToText[this.ruleForm.ShippingAddress[i]]);
        addressCode += this.ruleForm.ShippingAddress[i] + ",";
      }
      this.ruleForm.addressCode = addressCode.slice(0, addressCode.length - 1);
      this.selectedOptions = loc;
    },
  },
};
</script>

<style lang="scss" scoped>
#confirm-an-rder {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fff;
  border-top: 1px solid var(--subjectColor);
  // padding-bottom: 60px;
  background: #fff;
  ::v-deep .el-steps {
    width: 520px;
    padding: 30px 0;
    .el-step:nth-child(1) .el-step__line {
      background-color: #00c45c;
    }
    .el-step__head {
      .el-step__icon {
        width: 26px;
        height: 26px;
        .el-step__icon-inner {
          font-weight: 400;
        }
        i::before {
          color: #00c45c;
          font-weight: 700;
        }
      }
    }
    .el-step__main {
      .is-success {
        color: #00c45c;
      }
      .el-step__title {
        font-size: 14px;
      }
    }
    .is-success {
      border-color: #00c45c;
    }
    .is-process {
      color: #00c45c;
      border-color: #00c45c;
      .is-text {
        background: #00c45c;
        color: #fff;
      }
    }
  }
  .order-address {
    width: 1200px;
    display: flex;
    flex-direction: column;
    h2 {
      font-size: 20px;
      font-weight: bold;
      line-height: 26px;
      color: #333333;
      margin-bottom: 20px;
    }
    .consignee-information {
      padding: 20px;

      border: 1px solid #dddddd;
      .title {
        display: flex;
        justify-content: space-between;
        line-height: 22px;
        margin-bottom: 16px;

        p {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: #086ac2;
          cursor: pointer;
        }
      }
      ul {
        overflow: hidden;
        transition: all 0.5s;
        li:hover {
          background: #f7f7f7;
          .operation {
            display: block !important;
          }
        }
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 16px;
          .user {
            display: flex;
            font-size: 14px;
            font-weight: 400;
            align-items: center;
            color: #333333;
            p {
              width: 100px;
              height: 36px;
              background: #fff;
              border: 1px solid #dddddd;
              opacity: 1;
              text-align: center;
              line-height: 34px;
              margin-right: 18px;
              position: relative;

              img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 16px;
              }
            }
            span {
              margin-left: 6px;
            }
            i {
              width: 64px;
              height: 24px;
              background: rgba(255, 244, 214, 0.39);
              opacity: 1;
              font-size: 12px;
              font-weight: 400;
              line-height: 24px;
              color: #d58856;
              text-align: center;
              margin-left: 10px;
            }
          }

          .operation {
            font-size: 14px;
            color: #086ac2;
            span {
              margin: 0 8px 0 10px;
              cursor: pointer;
            }
          }
        }
        .onUser {
          background: #f7f7f7;
          .user {
            p:after {
              content: "";
              top: -1px;
              position: absolute;
              width: 96px;
              display: block;
              height: 32px;
              background: rgba(255, 255, 255, 0);
              border: 2px solid var(--subjectColor);
              opacity: 1;
            }
          }
        }
      }
      .more {
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        cursor: pointer;
        width: 72px;
        .svg-icon {
          font-size: 12px;
          margin-left: 4px;
          transition: all 0.5s;
        }
      }
    }
    .pay {
      border: 1px solid #dddddd;
      border-top: none;
      padding: 20px;
      display: flex;
      align-items: center;
      h3 {
        font-size: 16px;
        font-weight: bold;
        line-height: 22px;
        color: #333333;
      }
      p {
        width: 120px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        border: 1px solid #dddddd;
        opacity: 1;
        position: relative;
        font-size: 14px;
        color: #333333;

        img {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 16px;
        }
      }
      p:after {
        content: "";
        top: -1px;
        position: absolute;
        width: 116px;
        display: block;
        height: 32px;
        background: rgba(255, 255, 255, 0);
        border: 2px solid var(--subjectColor);
        opacity: 1;
      }
    }
  }
  .order-content {
    width: 1200px;
    border: 1px solid #dddddd;
    margin: 24px 0;
    border-bottom: none;
    position: relative;
    .title {
      background: #f2f7ff;
      height: 46px;
      line-height: 46px;
      padding: 0 20px;
      font-size: 16px;
      color: #333333;
      .svg-icon {
        color: #898a8c;
      }
      span {
        font-weight: bold;
        // cursor: pointer;
      }
      // span:hover {
      //   color: var(--subjectColor);
      // }
    }
    .content {
      padding: 30px 20px;
      h3 {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        color: #333333;
      }
      .goods-box {
        border: 1px solid #e2edf8;
        margin-top: 10px;
        background: #f9fbff;
        .goods-title {
          display: flex;
          padding: 12px 20px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          p:nth-child(1) {
            margin-right: 454px;
          }
          p:nth-child(2) {
            margin-right: 120px;
          }
          p:nth-child(3),
          p:nth-child(4) {
            margin-right: 130px;
          }
        }
        .goods {
          border-top: 1px solid #e2edf8;
          display: flex;
          padding: 20px;
          font-size: 14px;
          color: #333333;

          img {
            margin-right: 14px;
          }
          .details {
            width: 340px;
            padding-right: 20px;
            // cursor: default;
            h3 {
              height: 21px;
              font-size: 16px;
              font-weight: 400;

              margin-bottom: 15px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              // cursor: pointer;
            }
            // h3:hover {
            //   color: var(--subjectColor);
            //   span {
            //     color: #333;
            //   }
            // }
            p {
              height: 20px;

              font-weight: 400;
              color: #999999;
              margin-bottom: 8px;
              span {
                color: #666666;
              }
            }
          }
          .num {
            width: 114px;
            text-align: center;
          }
          .unit-price {
            width: 138px;
            text-align: end;
          }
          .money {
            margin-left: 62px;
            width: 140px;
            text-align: end;
            font-weight: bold;
          }
          .freight {
            margin-left: 144px;
          }
        }
      }
      .leave-a-message {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        background: #f9fbff;
        border: 1px solid #e2edf8;
        border-top: none;

        .left {
          display: flex;
          font-size: 14px;
          line-height: 28px;
          color: #333333;

          input {
            width: 580px;
            height: 36px;
            border: 1px solid #dddddd;
            opacity: 1;
            padding: 0 84px 0 8px;
            outline: none;
            font-size: 14px;
            transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
          input:hover {
            border-color: #c0c4cc;
          }
          .cont {
            position: relative;
            p {
              position: absolute;
              right: 12px;
              top: 4px;
              span {
                color: var(--subjectColor);
              }
              i {
                color: #999999;
              }
            }
          }
          .text {
            textarea {
              width: 580px;
              height: 100px;

              font-family: Microsoft YaHei;
              outline: none;
              resize: none;
              border: 1px solid var(--subjectColor);
              font-size: 14px;
              line-height: 20px;
              color: #333333;
              padding: 8px;
            }
            div {
              display: flex;
              justify-content: space-between;
              p:nth-child(1) {
                color: #999999;
              }
              p {
                span {
                  color: var(--subjectColor);
                }
                i {
                  color: #999999;
                }
              }
            }
          }
          :-moz-placeholder {
            /* Mozilla Firefox 4 to 18 */
            color: #999999;
          }

          ::-moz-placeholder {
            /* Mozilla Firefox 19+ */
            color: #999999;
          }

          input:-ms-input-placeholder,
          textarea:-ms-input-placeholder {
            color: #999999;
          }

          input::-webkit-input-placeholder,
          textarea::-webkit-input-placeholder {
            color: #999999;
          }
        }
        .right {
          font-size: 14px;
          color: #333333;
          p:nth-child(1) {
            margin-bottom: 10px;
            span {
              color: #fe3132;
              font-size: 16px;
            }
          }
          p {
            text-align: end;
          }
        }
      }
    }
    .waves::before {
      content: "";
      position: absolute;
      bottom: -3px;
      left: -1px;
      width: 1200px;
      height: 0.25em;
      background: -webkit-linear-gradient(
          315deg,
          transparent,
          transparent 45%,
          #dddddd,
          transparent 55%,
          transparent 100%
        ),
        -webkit-linear-gradient(45deg, transparent, transparent 45%, #dddddd, transparent
              55%, transparent 100%);
      background: linear-gradient(
          135deg,
          transparent,
          transparent 45%,
          #dddddd,
          transparent 55%,
          transparent 100%
        ),
        linear-gradient(
          45deg,
          transparent,
          transparent 45%,
          #dddddd,
          transparent 55%,
          transparent 100%
        );
      background-size: 0.5em 0.5em;
      background-repeat: repeat-x, repeat-x;
    }
  }
  .settlement {
    border: 1px solid #e8e8e8;
    background: #f5f5f5;
    width: 1200px;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding-left: 20px;
    margin-bottom: 60px;
    p {
      cursor: pointer;
    }
    .rental {
      margin-right: 24px;
      span {
        font-size: 20px;
        color: #fe3132;
      }
    }
    button {
      width: 140px;
      height: 56px;
      background: var(--subjectColor);
      border: none;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .form {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.39);
    z-index: 2000;
    .box {
      width: 694px;
      height: 480px;
      background: rgba(255, 255, 255, 1);
      opacity: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -347px;
      margin-top: -240px;
      border: 5px solid rgba(0, 0, 0, 0.55);
      border-radius: 4px;
      .title {
        width: 100%;
        background: #f5f5f5;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
        .svg-icon {
          cursor: pointer;
          font-size: 18px;
        }
      }

      ::v-deep .el-form {
        padding: 24px;
        padding-bottom: 0;
        .el-form-item {
          margin-bottom: 16px;
          .el-form-item__label:before {
            margin: 0;
          }
          .el-form-item__content {
            .el-input {
              input {
                width: 300px;
                border-radius: 0;
                padding: 0 12px;
              }
              .el-input__suffix {
                left: 304px;
                right: auto;
                font-size: 16px;
                color: #22bb55;
              }
            }
            .el-form-item__error {
              left: 330px;
              top: 23%;
            }
            .el-input__inner:focus {
              border-color: var(--subjectColor) !important;
            }
            .el-input__inner:target {
              border-color: var(--subjectColor);
            }
            .el-cascader .el-input.is-focus .el-input__inner {
              border-color: var(--subjectColor);
            }
            .el-radio__input.is-checked .el-radio__inner {
              border-color: var(--subjectColor);
              background: var(--subjectColor);
            }
            .el-radio__inner:hover {
              border-color: var(--subjectColor);
            }
            .el-radio__input.is-checked + .el-radio__label {
              color: var(--subjectColor);
            }
          }
        }
        .el-form-item:last-child {
          .el-form-item__error {
            left: 122px;
            top: 23%;
          }
        }
        .el-form-item:nth-child(2) {
          .el-cascader {
            line-height: 32px;
          }
          .el-input__inner {
            height: 34px;
          }
          .el-input__suffix-inner {
            position: absolute;
            left: -36px;
            top: -3px;
            color: #dcdfe6;
          }
          .el-icon-circle-close {
            line-height: 34px;
          }
        }
      }
      button {
        width: 132px;
        height: 36px;
        background: var(--subjectColor);
        border-radius: 2px;
        border: none;
        font-size: 14px;
        line-height: 34px;
        color: #ffffff;
        cursor: pointer;
        margin-left: 144px;
      }
    }
  }
}
</style>
<style lang="scss">
.el-popper {
  .el-cascader-node.in-active-path {
    color: var(--subjectColor) !important;
  }
  .el-cascader-node.is-active {
    color: var(--subjectColor) !important;
  }
}
</style>